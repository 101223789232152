import dayjs from "dayjs";
import React, { Component } from "react";
import { connect } from "react-redux";
import { teacherActions } from "../../../../../core/teachers";
import Compose from "./compose";
import Confirm from "./confirm";
import Success from "./success";
import template from "./template";
import templateCertification from "./template-certification";

class WelcomeEmail extends Component {
  constructor(props) {
    super(props);
    const {
      allTeachersToEmailHave1000Books,
      emailTemplate,
      organisationName,
    } = props;
    const isCertificationEmail = emailTemplate === "CERTIFICATION";
    const yesterday = dayjs().add(14, "days").format("MMMM Do YYYY");
    let welcomeEmailBody = template.replace("{date}", yesterday);
    if (allTeachersToEmailHave1000Books) {
      welcomeEmailBody = welcomeEmailBody.replace(
        "{license}",
        `\nNow you have premium access - unlimited libraries, 1000 books, ability for your students to collaborate and for you to add co-teachers to your libraries!\n`
      );
    } else {
      welcomeEmailBody = welcomeEmailBody.replace("{license}", "");
    }
    let certificationEmailBody = templateCertification.replace(
      "{date}",
      yesterday
    );
    const subject = `${organisationName} has sent you a message`;
    this.state = {
      showConfirmation: false,
      composeHasBeenShown: false,
      subject,
      message: isCertificationEmail ? certificationEmailBody : welcomeEmailBody,
    };
  }

  componentWillUnmount() {
    const { setEmailSent, setTeachersToEmail, setEmailTemplate } = this.props;
    setTeachersToEmail(null);
    setEmailSent(false);
    setEmailTemplate(null);
  }

  handleMessageChange = message => {
    this.setState({ message });
  };

  handleSubmit = () => {
    const { message } = this.state;
    const { teachersToEmail, sendWelcomeEmail } = this.props;
   
    sendWelcomeEmail({
      message,
      teachers: teachersToEmail.toJS(),
    });
  };

  handleClose = () => {
    this.props.setTeachersToEmail(null);
  };

  render() {
    const {
      teachersToEmail,
      teachersToEmailAddresses,
      emailingTeachers,
      emailSent,
    } = this.props;
    const {
      showConfirmation,
      subject,
      message,
      composeHasBeenShown,
    } = this.state;

    if (emailSent) {
      return <Success onClose={this.handleClose} />;
    } else if (showConfirmation) {
      return (
        <Confirm
          onClose={this.handleClose}
          onSubmit={this.handleSubmit}
          onBackButtonClick={e => this.setState({ showConfirmation: false })}
          emailingTeachers={emailingTeachers}
          teachersToEmail={teachersToEmail}
          teachersToEmailAddresses={teachersToEmailAddresses}
        />
      );
    } else {
      return (
        <Compose
          onClose={this.handleClose}
          subject={subject}
          message={message}
          transition={!composeHasBeenShown}
          onMessageChange={this.handleMessageChange}
          onSubmit={e =>
            this.setState({
              showConfirmation: true,
              composeHasBeenShown: true,
            })
          }
        />
      );
    }
  }
}

const mapDispatchToProps = {
  ...teacherActions,
};

export default connect(null, mapDispatchToProps)(WelcomeEmail);
