import classNames from "classnames";
import { is } from "immutable";
import React, { useEffect, useRef, useState } from "react";
import { Tooltip } from "react-tooltip";
import ChevronIcon from "../../../images/svg/chevron.svg";
import HelpIcon from "../../../images/svg/help--round.svg";
import TriangleIcon from "../../../images/svg/triangle.svg";
import SvgIcon from "../../components/ui/svg-icon";
import Checkbox from "../forms/checkbox";
import Dropdown from "../forms/dropdown";
import "./index.scss";
import { sortRows } from "./row-sort";

const PAGE_SIZES = [10, 20, 50, 100];

const SortButtons = ({ onClick, heading, sortBy }) => {
  const sort = sortBy.split(".");
  const ascClasses = classNames("table__heading-sort", "table__heading-sort--asc", {
    active: heading.sortable === sort[0] && sort[1] === "ASC",
  });
  const descClasses = classNames("table__heading-sort", "table__heading-sort--desc", {
    active: heading.sortable === sort[0] && sort[1] === "DESC",
  });

  return (
    <div className="table__heading-controls">
      <button
        className={ascClasses}
        onClick={e => {
          onClick(`${heading.sortable}.ASC`);
          e.stopPropagation();
        }}>
        <SvgIcon icon={TriangleIcon} name="triangle" />
      </button>
      <button
        className={descClasses}
        onClick={e => {
          onClick(`${heading.sortable}.DESC`);
          e.stopPropagation();
        }}>
        <SvgIcon icon={TriangleIcon} name="triangle" />
      </button>
    </div>
  );
};

const Table = props => {
  const { data, sortBy, onDeselectRows, onPageSizeChange, onRowsChange, selectedRows } = props;

  const [pagination, setPagination] = useState({
    pageSize: 10,
    currentPage: 0,
  });

  const [rows, setRows] = useState([]);
  const { pageSize, currentPage } = pagination;

  const prevPageSize = useRef(pageSize);
  const prevRows = useRef(rows);

  const getRows = React.useMemo(() => {
    const skip = pageSize * currentPage;
    let t = data.body;

    if (sortBy) {
      t = sortRows(t, sortBy);
    }

    return t?.skip(skip).take(pageSize) || [];
  }, [data.body, sortBy, currentPage, pageSize]);

  useEffect(() => {
    if (!is(getRows, rows)) {
      setRows(getRows);
    }
  }, [getRows, rows]);

  useEffect(() => {
    if (onDeselectRows && (prevPageSize.current > pageSize || prevRows.current.size > rows.size)) {
      const rowsToDeselect = [];
      selectedRows.forEach(selectedRow => {
        const i = rows.findIndex(row => row.rowId === selectedRow);
        if (i < 0) {
          rowsToDeselect.push(selectedRow);
        }
      });
      onDeselectRows(rowsToDeselect);
    }

    if (onPageSizeChange && pageSize !== prevPageSize.current) {
      onPageSizeChange(pageSize);
    }

    if (onRowsChange && !is(rows, prevRows.current)) {
      onRowsChange(rows.toJS ? rows.toJS() : rows);
    }

    // Update refs to current state
    prevPageSize.current = pageSize;
    prevRows.current = rows;
  }, [pageSize, rows, onDeselectRows, onPageSizeChange, onRowsChange, selectedRows]);

  const setPage = nextPage => {
    if (props.resetSelection) {
      props.resetSelection();
    }
    setPagination(prev => ({
      ...prev,
      currentPage: nextPage,
    }));
  };

  const setPageSize = value => {
    const nextPageSize = parseInt(value, 10);
    const nextPageIndex = pagination.currentPage * nextPageSize > props.data.body.size ? 0 : pagination.currentPage;

    setPagination(prev => ({
      ...prev,
      pageSize: nextPageSize,
      currentPage: nextPageIndex,
    }));
  };

  const renderHeadings = () => {
    const { data, onHeadingClick, sortBy } = props;
    return data.headings.map((heading, i) => {
      const sort = sortBy ? sortBy.split(".") : null;
      const nextSortBy = sortBy
        ? sort[0] === heading.sortable
          ? sort[1] === "ASC"
            ? `${heading.sortable}.DESC`
            : `${heading.sortable}.ASC`
          : `${heading.sortable}.ASC`
        : null;

      return (
        <th
          className={heading.sortable ? "table__sortable-heading" : null}
          key={i}
          style={{
            width: heading.width || null,
            textAlign: heading.align,
          }}
          onClick={e => {
            onHeadingClick && onHeadingClick(nextSortBy);
            resetPages();
            deselectAll();
          }}>
          <span className="table__sortable-heading-name">
            {heading.name}
            {heading.tooltip && (
              <span
                className="tooltip"
                onClick={evt => evt.stopPropagation()}
                data-tooltip-id={`tooltip-${heading.name}`}>
                <SvgIcon icon={HelpIcon} name="help" />
                <Tooltip id={`tooltip-${heading.name}`} html={heading.tooltip} clickable multiline></Tooltip>
              </span>
            )}
          </span>
          {heading.sortable && (
            <SortButtons
              onClick={payload => {
                onHeadingClick && onHeadingClick(payload);
                resetPages();
                deselectAll();
              }}
              sortBy={sortBy}
              heading={heading}
            />
          )}
        </th>
      );
    });
  };

  const renderBody = () => {
    const { selectable, rowHeight, data, onRowSelect } = props;

    return rows.map((row, i) => {
      const rowClass = classNames({
        "table__row--selected": row.selected,
        "table__row--warning": row.warning,
        "table__row--disabled": row.disabled,
        [`table__row--${row.align}`]: row.align,
        [`${row.className}`]: row.className,
      });

      return (
        <tr
          key={i}
          data-id={row.rowId}
          className={rowClass}
          style={{ height: rowHeight }}
          onClick={selectable && onRowSelect ? () => onRowSelect([row.rowId]) : null}>
          {selectable && (
            <td className="table__checker">
              <Checkbox checked={row.selected} />
            </td>
          )}
          {row.cells.map((cell, j) => (
            <td key={j} style={{ textAlign: data.headings[j].align }}>
              {cell}
            </td>
          ))}
        </tr>
      );
    });
  };

  const selectAll = () => {
    if (allSelected()) {
      deselectAll();
    } else {
      const ids = rows.map(row => row.rowId);
      props.onSelectAll && props.onSelectAll(ids);
    }
  };

  const deselectAll = () => {
    const ids = rows.map(row => row.rowId);
    props.onDeselectAll && props.onDeselectAll(ids);
  };

  const allSelected = () => {
    return selectedRows && rows.size && selectedRows.size === rows.size;
  };

  const resetPages = () => {
    setPagination(prev => ({
      ...prev,
      currentPage: 0,
    }));
  };

  const { selectable, className } = props;
  const { body } = data;

  const morePagesToCome = body.size > pageSize * (currentPage + 1);
  const pageCount = Math.ceil(body.size / pageSize);
  const currentPageMax = Math.min((currentPage + 1) * pageSize, body.size);
  const currentRange = `${currentPage * pageSize + 1} - ${currentPageMax} of ${body.size}`;

  const pageOptions = Array.from({ length: pageCount }, (_, i) => ({
    value: i,
    label: i + 1,
  }));

  const cssClass = classNames("table", {
    "table--selectable": selectable,
    [className]: className,
  });

  return (
    <div className={cssClass}>
      <table>
        <thead>
          <tr>
            {selectable && (
              <th className="table__checker">
                <Checkbox checked={allSelected()} onClick={selectAll} />
              </th>
            )}
            {renderHeadings()}
          </tr>
        </thead>
        <tbody>{renderBody()}</tbody>
      </table>
      {body.size > 10 && (
        <div className="table__controls">
          <span className="table__control-label">Page:</span>
          <div style={{ width: currentPage >= 9 ? 62 : 56 }}>
            <Dropdown
              value={pageOptions[currentPage]}
              triangle
              onChange={e => setPage(e.value)}
              options={pageOptions}
            />
          </div>
          <span className="table__control-label">Rows per page:</span>
          <div style={{ width: pageSize > 99 ? 72 : 62 }}>
            <Dropdown
              value={{ value: pageSize, label: pageSize }}
              triangle
              onChange={e => setPageSize(e.value)}
              options={PAGE_SIZES.map(size => ({
                value: size,
                label: size,
              }))}
            />
          </div>
          <span className="table__page-range">{currentRange}</span>
          <button className="table__previous" onClick={() => setPage(currentPage - 1)} disabled={currentPage === 0}>
            <span>
              <SvgIcon icon={ChevronIcon} name="chevron" />
            </span>
          </button>
          <button className="table__next" onClick={() => setPage(currentPage + 1)} disabled={!morePagesToCome}>
            <span>
              <SvgIcon icon={ChevronIcon} name="chevron" />
            </span>
          </button>
        </div>
      )}
    </div>
  );
};

export default Table;
